import * as React from "react";
import { Box, Menu as MuiMenu, MenuItem, Button } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { NavItems } from "./Header";
import { HashNavLink, HashNavButton } from "../custom/HashNav";
import LanguageSwitch from "../LanguageSwitch";
import ReactGA from "react-ga4";
import { useState } from "react";
import { useTranslation } from "react-i18next";

type MenuProps = {
  navItems: NavItems;
};

const Menu = ({ navItems }: MenuProps) => {
  const [services, setServices] = useState<null | HTMLElement>(null);
  const { t } = useTranslation("", { keyPrefix: "Header" });

  const clickHandler = (url: string) => {
    ReactGA.event({
      category: "Button",
      action: url,
      label: "Bookmark",
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setServices(event.currentTarget);
  };

  const handleClose = () => {
    setServices(null);
  };

  const serviceSubItems = [
    { id: 5, title: t("serviceSubItems.customIIoT"), url: "/custom-iiot" },
    { id: 6, title: t("serviceSubItems.ourOffering"), url: "/#service" },
    { id: 7, title: t("serviceSubItems.ourWorkshops"), url: "/#our-workshop" },
  ];

  const menuList = navItems.map(({ id, title, url, btn }, i, arr) => {
    if (title === "languageSwitch") {
      return (
        <LanguageSwitch
          key={id}
          sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
          underline="none"
        />
      );
    }

    if (title === "Service") {
      return (
        <React.Fragment key={id}>
          <Button
            aria-controls="service-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color="inherit"
            endIcon={<ArrowDropDownIcon />}
            sx={{
              ...(arr.length - 1 !== i && { mr: 9 }),
              fontSize: "16px",
              fontWeight: 400,
            }}
          >
            {title}
          </Button>
          <MuiMenu
            id="service-menu"
            anchorEl={services}
            open={Boolean(services)}
            onClose={handleClose}
            PaperProps={{
              sx: {
                borderRadius: "8px",
                mt: 1,
                minWidth: 200,
                boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
                "& .MuiMenuItem-root": {
                  borderBottom: "1px solid #e0e0e0",
                  py: "6px",
                  "&:last-child": {
                    borderBottom: "none",
                  },
                  fontSize: "13px",
                  fontWeight: "400",
                  color: "#333",
                },
              },
            }}
          >
            {serviceSubItems.map((subItem) => (
              <MenuItem
                key={subItem.id}
                component={HashNavLink}
                to={subItem.url}
                onClick={() => {
                  handleClose();
                  clickHandler(subItem.url);
                }}
                sx={{
                  padding: "8px 16px",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
              >
                {subItem.title}
              </MenuItem>
            ))}
          </MuiMenu>
        </React.Fragment>
      );
    }

    if (!btn) {
      return (
        <HashNavLink
          onClick={() => clickHandler(url)}
          key={id}
          to={url}
          underline="none"
          sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
        >
          {title}
        </HashNavLink>
      );
    }

    return (
      <HashNavButton
        onClick={() => clickHandler(url)}
        key={id}
        variant="contained"
        to={url}
        sx={{ ...(arr.length - 1 !== i && { mr: 9 }) }}
      >
        {title}
      </HashNavButton>
    );
  });

  return <Box sx={{ display: { xs: "none", md: "block" } }}>{menuList}</Box>;
};

export default Menu;
