import { Fragment, useState } from "react";
import { NavHashLink } from "react-router-hash-link";
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Slide,
  Collapse,
  Backdrop,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Turn as Hamburger } from "hamburger-react";
import { NavItems } from "./Header";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

type MobileMenuProps = {
  navItems: NavItems;
  onDrawerToggle: () => void;
  mobileOpen: boolean;
};

const MobileMenu = ({
  navItems,
  onDrawerToggle,
  mobileOpen,
}: MobileMenuProps) => {
  const [serviceOpen, setServiceOpen] = useState(false);
  const { t } = useTranslation("", { keyPrefix: "Header" });

  const clickHandler = (url: string) => {
    ReactGA.event({
      category: "Button",
      action: url,
      label: "Bookmark",
    });
    setServiceOpen(false); // Close the service submenu
    onDrawerToggle();
  };

  const handleServiceClick = () => {
    setServiceOpen(!serviceOpen);
  };

  const serviceSubItems = [
    { id: 5, title: t("serviceSubItems.customIIoT"), url: "/custom-iiot" },
    { id: 6, title: t("serviceSubItems.ourOffering"), url: "/#service" },
    { id: 7, title: t("serviceSubItems.ourWorkshops"), url: "/#our-workshop" },
  ];

  const drawer = (
    <Box>
      <Box
        sx={{
          height: "76px",
          width: "100%",
          bgcolor: "background.default",
          position: "sticky",
          zIndex: 1,
          top: 0,
        }}
      />
      <List sx={{ px: { xs: 3, sm: 4 } }}>
        {navItems.map(({ id, title, url }, i) => {
          if (title === "languageSwitch") return null;

          if (title === "Service") {
            return (
              <Fragment key={id}>
                <Slide
                  direction="right"
                  in={mobileOpen}
                  timeout={500}
                  style={{ transitionDelay: `${i * 100}ms` }}
                >
                  <ListItem disablePadding>
                    <ListItemButton onClick={handleServiceClick} disableGutters>
                      <ListItemText
                        sx={{
                          py: 1,
                          color: "text.primary",
                        }}
                        primary={title}
                      />
                      {serviceOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListItem>
                </Slide>
                <Collapse in={serviceOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {serviceSubItems.map((subItem) => (
                      <ListItem key={subItem.id} disablePadding>
                        <ListItemButton
                          onClick={() => clickHandler(subItem.url)}
                          component={NavHashLink}
                          smooth
                          to={subItem.url}
                          divider
                          disableGutters
                        >
                          <ListItemText
                            sx={{
                              py: 1,
                              pl: 4,
                              color: "text.primary",
                            }}
                            primary={subItem.title}
                          />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            );
          }

          return (
            <Slide
              key={id}
              direction="right"
              in={mobileOpen}
              timeout={500}
              style={{ transitionDelay: `${i * 100}ms` }}
            >
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => clickHandler(url)}
                  component={NavHashLink}
                  smooth
                  to={url}
                  divider
                  disableGutters
                >
                  <ListItemText
                    sx={{
                      py: 1,
                      color: "text.primary",
                    }}
                    primary={title}
                  />
                </ListItemButton>
              </ListItem>
            </Slide>
          );
        })}
      </List>
    </Box>
  );

  return (
    <Fragment>
      <Box sx={{ display: { md: "none" }, zIndex: 1300 }}>
        <Hamburger
          toggled={mobileOpen}
          toggle={onDrawerToggle}
          rounded
          size={28}
        />
      </Box>
      <Backdrop
        open={mobileOpen}
        onClick={onDrawerToggle}
        sx={{ display: { xs: "block", md: "none" } }}
      />
      <Drawer
        variant="persistent"
        open={mobileOpen}
        onClose={onDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        transitionDuration={400}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: "90%",
            maxWidth: "300px",
          },
        }}
      >
        {drawer}
      </Drawer>
    </Fragment>
  );
};

export default MobileMenu;
