import {
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Portfolio = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("", { keyPrefix: "Portfolio" });
  const post: {
    picture2: any;
    info: string;
    title: string;
    description: string;
    image: { path: string; alt: string };
    labels: string[];
    review: { text: string; author: string };
    information: { title: string; description: string }[];
    text: string;
    picture: { path: string; alt: string };
    footer: {
      title: string;
      description: string;
      image: { path: string; alt: string };
    };
  } = t(id, { returnObjects: true });

  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid container>
      <Grid container bgcolor="tertiary.light">
        <Container>
          <Grid container item py={10} spacing={8}>
            <Grid container item xs={12} md={6}>
              <Typography
                variant="h3"
                fontWeight={700}
                component="h2"
                data-aos="fade-up"
                sx={isMatch ? { fontSize: "2.5rem" } : {}}>
                {post.title}
              </Typography>
              <Typography
                variant="body1"
                data-aos="fade-up"
                data-aos-delay="200"
                mt={5}>
                {post.description}
              </Typography>
              <Grid item>
                <Typography
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="400"
                  mt={5}
                  fontWeight={700}>
                  Key words:
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  mt={3}
                  sx={{
                    flexWrap: "wrap",
                    ".MuiChip-root": {
                      border: "1px solid #4F52FF",
                      borderRadius: "4px",
                      bgcolor: "#fff",
                      marginBottom: "1rem",
                      marginLeft: 0,
                      marginRight: "1rem",
                    },
                  }}>
                  {post.labels.map((label) => (
                    <Chip label={label} />
                  ))}
                </Stack>
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6}>
              <img
                style={{
                  objectFit: "cover",
                  height: "450px",
                  width: "100%",
                }}
                src={post.image.path}
                alt={post.image.alt}
              />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <Grid container item py={5}>
        <Container>
          <Grid container item textAlign="center">
            <hr
              style={{
                width: "10rem",
                backgroundColor: "#4F52FF",
                marginBottom: "40px",
                border: "none",
                height: "1px",
              }}
            />
            <Typography
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="200"
              width="100%">
              “{post.review.text}”
            </Typography>
            <Typography
              variant="body1"
              data-aos="fade-up"
              data-aos-delay="400"
              width="100%"
              mt={3}>
              {post.review.author}
            </Typography>
            <hr
              style={{
                width: "10rem",
                backgroundColor: "#4F52FF",
                marginTop: "40px",
                border: "none",
                height: "1px",
              }}
            />
          </Grid>
          <Grid container item spacing={5} mt={10}>
            {post.information.map((info) => (
              <Grid item>
                <div style={{ fontWeight: "700" }}>{info.title}</div>
                <div>{info.description}</div>
              </Grid>
            ))}
          </Grid>
          <hr
            style={{
              width: "100%",
              backgroundColor: "#4F52FF",
              marginTop: "20px",
              border: "none",
              height: "1px",
            }}
          />
        </Container>
      </Grid>
      <Grid container>
        <Container>
          <Grid container item spacing={8} my={5}>
            <Grid container item xs={12} md={6}>
              <Typography
                variant="body1"
                data-aos="fade-up"
                data-aos-delay="200"
                mt={5}>
                {post.text}
              </Typography>
            </Grid>
            <Grid container item xs={12} md={6}>
              <img src={post.picture.path} alt="" />
            </Grid>
          </Grid>
        </Container>
      </Grid>
      {post.picture2 && (
        <Grid container>
          <Container>
            <Grid container item spacing={8} my={5}>
              <Grid container item xs={12} md={6}>
                <img src={post.picture2.path} alt="" />
              </Grid>
              <Grid container item xs={12} md={6}>
                <Typography
                  variant="body1"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  mt={5}>
                  {post.info}
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      )}
      <Grid container bgcolor="tertiary.light" mt={10}>
        <Container>
          <Grid container item spacing={8} py={15}>
            <Grid container item xs={12} md={6}>
              <img
                style={{
                  objectFit: "cover",
                  height: "450px",
                  width: "100%",
                }}
                src={post.footer.image.path}
                alt={post.footer.image.alt}
              />
            </Grid>
            <Grid container item xs={12} md={6}>
              <Typography
                variant="h3"
                fontWeight={700}
                component="h2"
                data-aos="fade-up"
                sx={isMatch ? { fontSize: "2.5rem" } : {}}>
                {post.footer.title}
              </Typography>
              <Typography
                variant="body1"
                data-aos="fade-up"
                data-aos-delay="200"
                mt={5}>
                {post.footer.description}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Portfolio;
